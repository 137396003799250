import "./sales.scss";

class _Controller {
  constructor(UserService, Auth, $timeout) {
    this.Auth = Auth;
    this.UserService = UserService;
    this.$timeout = $timeout;
  }

  $onInit() {
    this.Auth.currentUser().then(response => {
      const { user } = response;
      this.UserService.initialize(user);
      this.user = this.UserService.user;
    
      this.$timeout(() => {
        var drift = drift || null;
        if (!!drift) {
          drift.identify('USER-' + this.user.id, {
            email: this.user.email,
          });
        }
      }, 100)
    }).catch(() => {
      // User is not logged in, ignore
    })
  }
}

_Controller.$inject = ['UserService', 'Auth', '$timeout'];

export const sales = {
  controller: _Controller,
  template: require('./sales.html')
};
