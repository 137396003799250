export const $redirects = () => {

  var redirects = {};

  const userAccessingUserFunctions = (user, toState) => {
    if (['login', 'signUp', 'passwordReset', 'passwordEdit'].includes(toState.name)) throw 'messageList';
  }

  redirects.check = (user, toState) => {
    if (!user) return null;
    try {
      userAccessingUserFunctions(user, toState);
      return false;
    } catch (url) {
      // console.log(`Redirected to ${url}`);
      return url;
    }
  };

  return redirects;
}