import "./blog.scss";

class _Controller {
  constructor() {
    const $postList = $('#post-list');
    $('#post-list').remove();
    $('#post-list-container').append($postList);
  }
}

export const blogIndex = {
  controller: _Controller,
  template: require('./blogIndex.html')
};
