class _Controller {
  constructor() {
    const $postEntry = $('#post-entry');
    $('#post-entry').remove();
    $('#post-entry-container').append($postEntry);

    debugger
  }
}

export const blogShow = {
  controller: _Controller,
  template: require('./blogShow.html')
};
