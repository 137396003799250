import "./documentation.scss";

class _Controller {
  constructor($sce, $window, $timeout, $location) {
    this.$sce = $sce;
    this.$timeout = $timeout;
    this.$window = $window;
    this.$location = $location;
    this.templates = {
      dunning: this.trust(require("./emails/dunning.html")),
      preDunning: this.trust(require("./emails/preDunning.html")),
      purchased: this.trust(require("./emails/purchased.html")),
      cancellation: this.trust(require("./emails/cancellation.html")),
      cardUpdated: this.trust(require("./emails/cardUpdated.html")),
      changedPlan: this.trust(require("./emails/changedPlan.html")),
      chargeSucceeded: this.trust(require("./emails/chargeSucceeded.html")),
      invoiceReceipt: this.trust(require("./emails/invoiceReceipt.html")),
      renewed: this.trust(require("./emails/renewed.html")),
      renewedBefore: this.trust(require("./emails/renewedBefore.html")),
      trialExpired: this.trust(require("./emails/trialExpired.html")),
      trialStart: this.trust(require("./emails/trialStart.html")),
      trialWillEnd: this.trust(require("./emails/trialWillEnd.html")),
      changedPlan: this.trust(require("./emails/changedPlan.html")),
    }
    this.docSection = "docs-overview";
    this.offsets = {};
    this.disableScroll = false;
    this.links = [
      {id: "overview", title: "Overview"},
      {id: "connecting-to-stripe", title: "Connecting to Stripe"},
      {id: "types-of-email", title: "Email triggers"},
      {id: "trial-start", title: "Started trial", indent: true},
      {id: "trial-will-end", title: "Trial ends soon", indent: true},
      {id: "trial-expired", title: "Trial expired", indent: true},
      {id: "welcome", title: "Subscription purchased", indent: true},
      {id: "renewed-before", title: "Subscription will renew", indent: true},
      {id: "renewed", title: "Subscription renewed", indent: true},
      {id: "cancellation", title: "Subscription cancelled", indent: true},
      {id: "changed-plan", title: "Changed plan", indent: true},
      {id: "card-updated", title: "Updated card", indent: true},
      {id: "dunning", title: "Payment failed", indent: true},
      {id: "pre-dunning", title: "Payment will fail", indent: true},
      {id: "invoice-receipt", title: "Invoice paid", indent: true},
      {id: "charge-succeeded", title: "Charge suceeded", indent: true},
      {id: "email-editing", title: "Email editing"},
      {id: "liquid-markup", title: "Dynamic content", indent: true},
      {id: "previewing-emails", title: "Previewing emails", indent: true},
      {id: "test-sends", title: "Test sends", indent: true},
      {id: "activity-scheduled", title: "Activity and scheduled preview"},
      {id: "troubleshooting", title: "Troubleshooting"}
    ]
  }


  goToAnchor(linkId, e) {
    e.preventDefault();
    const keys = Object.keys(this.offsets);
    keys.forEach(key => {
      if (this.offsets[key] == `docs-${linkId}`) {
        window.scrollTo(0, key);
        this.disableScroll = true;
        this.docSection = `docs-${linkId}`
      }
    });
  }



  setVh() {
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  $onInit() {
    this.$timeout(() => {
      const $elements = $('.list-section h2, .list-section h3, .email-types > li')
      $elements.each((idx) => {
        const elem = $elements[idx];
        if (elem.id.split("-")[0] === "docs") {
          const _id = $elements[idx].id;
          const _elem = document.getElementById(_id)
          this.offsets[_elem.offsetTop] = _elem.id;
        }
      })
      angular.element(this.$window).bind("scroll", (e) => {
        this.onScroll(e.currentTarget.scrollY);
      });
      angular.element(this.$window).bind("resize", (e) => {
        this.setVh()
      });
      this.onScroll(window.scrollY);
      this.setVh();
    }, 200);
  }

  onScroll(scrollY) {
    if (this.disableScroll) {
      this.disableScroll = false;
      return false;
    }
    const keys = Object.keys(this.offsets);
    let highest = 0;
    keys.forEach(key => {
      if (scrollY + 100 > key) highest = key; 
    })
    const lastId = this.offsets[highest];
    if (lastId != this.docSection) {
      const _id = lastId || this.offsets[keys[0]];
      this.$timeout(()=> {
        this.docSection = _id;
        // if (!this.$location.hash() && _id === "docs-overview") {
        //   // nothing
        // } else {
        //   this.$location.replace().hash(_id);
        // }
      })
    }
  }

  trust(html) {
    return this.$sce.trustAsHtml(html);
  }
};

_Controller.$inject = ['$sce', '$window', '$timeout', '$location'];

export const documentation = {
  controller: _Controller,
  template: require("./documentation.html")
};

