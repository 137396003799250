import "./home.scss";

class _Controller {

  constructor() {}

  goToSignUp () {
    window.location.href = '/sign-up';
    // try {
    //   // goog_snippet_vars();
    //   // window.google_conversion_format = '3';
    //   this.$location.path('/sign-up');
    // } catch (err) {
    //   this.$location.path('/sign-up');
    // }
  }

}

_Controller.$inject = [];

export const home = {
  controller: _Controller,
  template: require('./home.html')
};
