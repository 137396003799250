export class UserService {

  constructor () {
    this.user = {};
  }

  initialize(user) {
    let builtUser = {
      // Merge in extra boolean helpers here, eg, 'isSubscriber'
      ...user
    };

    angular.copy(builtUser, this.user);
    return this.user;
  }

  reset() {
    angular.copy({}, this.user);
  }

}