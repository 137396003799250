export class $meta {

  constructor($window) {
    this.$window = $window;
    this.name = null;
    this.title = null;
    this.section = null;
    this.defaultTitle = 'Onboard';
  }

  setSection(section) {
    this.section = (section) ? section : null;
  }

  setPage(page) {
    this.page = (page) ? page : null;
  }
  
  setName(name) {
    this.name = (name) ? name : null;
    document.getElementsByTagName('body')[0].className = name;
  }

  setTitle(title) {
    title = title;
    this.title = title;
    this.$window.document.title = title;
    this.$window.document.querySelector('meta[property="og:title"]').setAttribute("content", title);
    this.$window.document.querySelector('meta[name="twitter:title"]').setAttribute("content", title);
  }

  setDescription(description) {
    if (description) {
      this.$window.document.querySelector('meta[name="description"]').setAttribute("content", description);
      this.$window.document.querySelector('meta[property="og:description"]').setAttribute("content", description);
      this.$window.document.querySelector('meta[itemprop="description"]').setAttribute("content", description);
      this.$window.document.querySelector('meta[name="twitter:description"]').setAttribute("content", description);
    }
  }

  setFromState(state) {
    if (!state.originalTitle) this.setTitle(state.title);
    this.setSection(state.section);
    this.setDescription(state.description);
    this.setPage(state.page);
    this.setName(state.name);
  }

}

$meta.$inject = ['$window'];
