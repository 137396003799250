import * as angular from "angular";
import uiRouter from "@uirouter/angularjs";
import * as ngSanitize from 'angular-sanitize';

require('AngularDevise/lib/devise-min');

import { SALES_MODULE } from '../sales/sales.module';

_.capitalize = function (string) {
  return string.charAt(0).toUpperCase() + string.substring(1).toLowerCase();
};

export const ngmodule = angular.module("onboardApp", [
  uiRouter,
  ngSanitize,
  'Devise',
  SALES_MODULE.name,
]);

ngmodule.config(['$uiRouterProvider', '$locationProvider', ($uiRouter, $locationProvider) => {
  $locationProvider.hashPrefix('');
  $locationProvider.html5Mode({
    enabled: true,
    requireBase: false
  });
}]);