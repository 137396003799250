class _Controller {

  constructor($location, $meta) {
    this.$location = $location;
    this.expanded = false;
    this.$meta = $meta;
  }

  goToSignUp () {
    window.location.href = '/sign-up';
  }

}

_Controller.$inject = ['$location', '$meta'];

export const navSales = {
  controller: _Controller,
  template: require('./navSales.html')
};