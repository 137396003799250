import { sales } from "./sales.component";
import { home } from "./home/home.component";
import { blogIndex } from "./blog/blogIndex.component";
import { blogShow } from "./blog/blogShow.component";
import { introGraphic } from "./home/introGraphic.component";
import { automationGraphic } from "./home/automationGraphic.component";
import { statics } from "./statics/statics.component";
import { documentation } from "./statics/documentation/documentation.component";
import { onboardLogo } from "./onboardLogo.component";
import { headerSales } from "./headerSales.component";
import { navSales } from "./navSales.component";
import { footerSales } from "./footerSales.component";

import { $redirects } from "../global/redirects.service";
import { $meta } from "../global/meta.service";
import { UserService } from "../global/user.service";
import { pageChangeHook } from "../global/pageChange.hook";

export const salesState = {
  name: 'sales',
  redirectTo: 'home',
  component: 'sales',
};

export const homeState = {
  name: 'home',
  url: '/',
  parent: 'sales',
  title: 'Onboard | Stripe emails with dunning, welcome and cancellation from Onboard',
  component: 'home',
};

export const staticsState = {
  name: 'statics',
  parent: 'sales',
  component: 'statics',
};

export const documentationState = {
  name: 'documentation',
  url: '/documentation',
  title: 'Documentation | Onboard',
  description: 'Learn how to automate Stripe emails with Onboard. Send trial emails, welcome, dunning, cancellation and more.',
  component: 'documentation',
  section: 'documentation'
};

export const faqState = {
  name: 'faq',
  url: '/faq',
  parent: 'statics',
  title: 'FAQ | Onboard',
  description: 'What emails does Onboard send? Can I create sequences of emails? Here are some answers to frequently asked questions.',
  template: require('./statics/faq.html'),
  section: 'faq'
};

export const blogIndexState = {
  name: 'blogIndex',
  url: '/blog',
  parent: 'statics',
  title: 'Blog | Onboard',
  description: 'What emails does Onboard send? Can I create sequences of emails? Here are some answers to frequently asked questions.',
  component: 'blogIndex',
  section: 'blog'
};

export const blogShowState = {
  name: 'blogShow',
  url: '/blog/:id',
  parent: 'statics',
  component: 'blogShow',
  section: 'blog',
  originalTitle: true
};

export const aboutState = {
  name: 'about',
  url: '/about',
  redirectTo: 'support'
  // parent: 'statics',
  // title: 'About | Onboard',
  // template: require('./statics/about.html')
};

export const supportState = {
  name: 'support',
  url: '/support',
  parent: 'statics',
  title: 'Support | Onboard',
  template: require('./statics/support.html'),
  section: 'support'
};

export const termsState = {
  name: 'terms',
  url: '/terms',
  parent: 'statics',
  title: 'Terms and Conditions | Onboard',
  template: require('./statics/terms.html')
};

export const SALES_MODULE = angular.module('sales', []);

SALES_MODULE.config(['$uiRouterProvider', 'AuthProvider', function($uiRouter, AuthProvider) {
  // Enable tracing of each TRANSITION... (check the javascript console)
  // $uiRouter.trace.enable(1);

  AuthProvider.loginPath('/users/login.json');
  AuthProvider.logoutPath('/users/logout.json');
  AuthProvider.resetPasswordMethod('PUT');
  AuthProvider.registerPath('/users.json');

  const $urlService = $uiRouter.urlService;
  $urlService.rules.otherwise({ state: 'home' });

  const $stateRegistry = $uiRouter.stateRegistry;
  $stateRegistry.register(salesState);
  $stateRegistry.register(homeState);
  $stateRegistry.register(staticsState);
  $stateRegistry.register(documentationState);
  $stateRegistry.register(faqState);
  $stateRegistry.register(blogIndexState);
  $stateRegistry.register(blogShowState);
  $stateRegistry.register(aboutState);
  $stateRegistry.register(supportState);
  $stateRegistry.register(termsState);

}]);

SALES_MODULE.component('sales', sales);
SALES_MODULE.component('home', home);
SALES_MODULE.component('statics', statics);
SALES_MODULE.component('blogIndex', blogIndex);
SALES_MODULE.component('blogShow', blogShow);
SALES_MODULE.component('documentation', documentation);
SALES_MODULE.component('onboardLogo', onboardLogo);
SALES_MODULE.component('headerSales', headerSales);
SALES_MODULE.component('navSales', navSales);
SALES_MODULE.component('footerSales', footerSales);
SALES_MODULE.component('introGraphic', introGraphic);
SALES_MODULE.component('automationGraphic', automationGraphic);

SALES_MODULE.service('$meta', $meta);
SALES_MODULE.service('$redirects', $redirects);
SALES_MODULE.service('UserService', UserService);
SALES_MODULE.value('transitionChecker', { inProgress: false })
SALES_MODULE.run(pageChangeHook);