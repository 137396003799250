import "./support.scss";
import "./faq.scss";

class _Controller {
  constructor() {
  }
}

export const statics = {
  controller: _Controller,
  template: require('./statics.html')
};
